<template>
  <div class="warehouse">
    <headNav></headNav>
    <div class="flex_m tabs">
      <div class="flex1 active">入库单</div>
      <div class="flex1" @click="jumpFun">出库单</div>
    </div>
    <van-form @submit="saveFun" class="w-box">
      <div class="mew-box">
        <div class="flex_m b-line h100">
          <span class="m-label"><i class="star-red fz26 mr5">*</i>客户名称</span>
          <van-field class="flex1" v-model="parmsJson.companyName" :rules="[{ required: true}]" placeholder="客户名称" />
        </div>
        <div class="flex_m h100">
          <span class="m-label"><i class="star-red fz26 mr5">*</i>仓库信息</span>
          <van-field class="flex1" @click="wPopupFun(true)" v-model="parmsJson.depotName" :rules="[{ required: true}]"
            right-icon="arrow-down" placeholder="仓库信息" readonly />
        </div>
      </div>
      <div class="mew-box mt30">
        <div class="flex_m b-line h100">
          <span class="m-label"><i class="star-red fz26 mr5">*</i>预计入库时间</span>
          <van-field @click="datePopupFun(true)" class="flex1" v-model="warehousingTime" :rules="[{ required: true}]"
            right-icon="arrow-down" placeholder="预计入库时间" readonly />
        </div>
        <div class="flex_m b-line h100">
          <span class="m-label"><i class="star-red fz26 mr5">*</i>存储天数</span>
          <van-field type='digit' class="flex1" v-model="parmsJson.storageDays" :rules="[{ required: true}]"
            placeholder="存储天数" />
          <span class="fz28 pr20">天</span>
        </div>
        <div class="flex_m h100">
          <span class="m-label"><i class="star-red fz26 mr5">*</i>入库需求</span>
          <div class="flex1 flex_end">
            <span @click='warehousingTypeFun(item)'
              :class="['tbutton_bg mr20',{'tbutton_bg_cur':parmsJson.warehousingType == item.value}]"
              v-for="item in warehousTypeArr">{{item.text}}</span>
          </div>
        </div>
      </div>
      <div class="flex_m mt20">
        <span class="flex1 f888 text-line" @click="jumpStandards">收货、收费标准</span>
        <van-button @click="addGoodsFun" class="plain bg-fff h70 pl30 pr30 icon_m b-radius-p50">选择货品明细</van-button>
      </div>
      <template v-if="parmsJson.commodityJson && parmsJson.commodityJson.length">
        <div class="mt20">
          <div class="t-tr bg-w">
            <div class="th" v-for="(thName,thIdx) in tHeadData">{{thName}}</div> <!-- :class="['th',{'w'+thIdx}]"  -->
            <div class="th lastW tc"></div>
          </div>
          <div class="t-tr" v-for="(item,index) in parmsJson.commodityJson">
            <div class="td w1">{{item.code}}</div>
            <div class="td w2">{{item.name}}</div>
            <div class="td">{{item.productionTime}}</div>
            <div class="td">
              <van-field type='digit' class="inpt" @input="inputEnterNumberFun(item,index)" v-model="item.enterNumber"
                :rules="[{ required: true}]" placeholder="入库数量" />
            </div>
            <div class="td lastW tc">
              <van-icon class="f333 fz34" name="delete-o" @click="deleteFun(index)" />
            </div>
          </div>
        </div>
        <div class="h100">
          <div class="f888 fz26 tr pt20">合计：<span
              class="icon_m">{{parmsJson.totalPakages}}箱、{{parmsJson.totalWeight}}kg、{{parmsJson.totalVolume}}方</span>
          </div>
        </div>
      </template>
      <div class="mew-box mt20">
        <div class="flex_m">
          <span class="m-label">备注</span>
          <van-field v-model="parmsJson.remark" rows="1" autosize type="textarea" placeholder="请输入" show-word-limit />
        </div>
      </div>
      <div class="flex_m h100 m-fixed bg-fff">
        <div class="flex1 f666 ml20 flex_m">
          <van-checkbox v-model="parmsJson.isChecked" @change="checkedFun" class="mr10" icon-size="24px"></van-checkbox>
          <span class="flex1 ml10">我已经阅读并同意<span class="icon_s" @click="agreementFun">《仓储条款》</span></span>
        </div>
        <van-button native-type="submit" class="btn-square">{{actType == 'edit' ? '修改入库订单':'生成入库订单' }}</van-button>
      </div>
    </van-form>

    <!--日期选择-->
    <ChooseTime :showDate="showDate" :defaultData="parmsJson.warehousingTime" @getTime="getTimeFun"
      @datePopupFun="datePopupFun"></ChooseTime>

    <!--仓库选择-->
    <ChooseWarehouse :type="'in'" :showCity="showCity" :depotOption='depotOption' @chooseWarehouse="chooseWarehouse" @wPopupFun="wPopupFun">
    </ChooseWarehouse>
  </div>

</template>
<script>
  import headNav from '@/components/mobile/headNav.vue'
  import ChooseTime from './components/m_choose_time.vue'
  import ChooseWarehouse from './components/m_choose_warehouse.vue'
  import { regExp } from "../../../assets/js/global";
  import { entryInitialParms , specsArr, warehousTypeArr, totalFun } from '@/assets/js/warehouse.js'
  const API = require('/src/assets/js/apilist.js');
  //获取用户信息
  let userJson = JSON.parse(localStorage.getItem('user'));
  
  export default {
    name: 'entry',
    components: {
      ChooseTime,
      ChooseWarehouse,
      headNav
    },
    data() {
      return {
        parmsJson: {...entryInitialParms}, //保存的集合
        showDate: false, //日期
        warehousTypeArr, //规格
        tHeadData: ['商品编号', '商品名称', '生产日期', '入库数量'], //已选商品的表头
        showCity: false,//仓库弹框
        warehousingTime: '',//格式化化时间到时
        rules: {
          integer: regExp('integer')
        },
        actType: '',//空:(默认)新建 add:再下一单 edit:编辑
        depotOption:[]

      };
    },
    mounted() {
      let id = this.$route.query.id || ''
      this.actType = this.$route.query.actType
      if (id) {
        // this.actType = 'edit'
        this.$set(this.parmsJson, 'id', id)
        this.getDetail(id)
      }
      //已经选择过的回选
      let parmsJson = localStorage.getItem('parmsJson')
      if (parmsJson) {
        this.parmsJson = JSON.parse(parmsJson)
        this.warehousingTime = this.parmsJson.warehousingTime ? this.parmsJson.warehousingTime.split(':')[0] + '时' : ''
        this.inputEnterNumberFun()
      }
      this.userMsgFun(userJson)
    },
    methods: {
      //编辑获取详情
      getDetail(id) {
        let self = this
        this.$axios('get', API.getById + '?id=' + id).then(res => {
          if (res && res.code == '200' && res.data) {
            let parmsJson = self.parmsJson
            let data = res.data
            Object.keys(parmsJson).map(function (key) {
              data[key] && (parmsJson[key] = data[key])
            })
            self.depotOption = (data.province && data.city && data.district && data.depotId) ? [data.province, data.city, data.district, data.depotId]:[]
            parmsJson.commodityJson = data.commodityJson ? JSON.parse(data.commodityJson) : []
            self.warehousingTime = parmsJson.warehousingTime.split(':')[0]+ '时' 
            self.parmsJson = parmsJson
          }
        })
      },
      //生成入库单
      saveFun() {

        let parmsJson = this.parmsJson
        let commodityJson = parmsJson.commodityJson
        let requiredTotalArr = [
          { valName: 'totalPakages', messages: '入库总数量没有计算' },
          { valName: 'totalVolume', messages: '入库总体积没有计算' },
          { valName: 'totalWeight', messages: '入库总重量没有计算' },
        ]
        commodityJson.length && requiredTotalArr.map(msg => {
          if (!parmsJson[msg.valName]) {
            this.$toast2.show(msg.messages, 'error')
            return false
          }
        })
        if (!this.parmsJson.isChecked) {
          this.$toast2.show('请阅读并同意《仓储条款》', 'warn')
          return false
        }
        localStorage.setItem('parmsJson', JSON.stringify(this.parmsJson))
        this.$router.push({ path: '/m_warehouse_detail', query: { type: 'in', id: '', actType: this.actType } });

      },
      //同意条款
      checkedFun(val) {
        // this.parmsJson.isChecked = val
        this.$set(this.parmsJson,'isChecked',val)
      },
      agreementFun(){
        localStorage.setItem('parmsJson', JSON.stringify(this.parmsJson))
        this.$router.push({ path: '/m_agreement' });
      },
      //补充客户信息
      userMsgFun(userJson) {
        this.parmsJson = {
          ...this.parmsJson,
          companyName: userJson.companyName || '', //公司名称
          corpId: userJson.corpId || '', //公司Id
          creator: userJson.id || '', //创建人
          updator: userJson.id || '', //修改人
          userId: userJson.id || '', //用户ID
          mobile: userJson.mobile || '', //手机号
        }
      },
      //仓库选择
      chooseWarehouse(data) {
        if (data) {
          this.parmsJson = {
            ...this.parmsJson,
            depotId: data.value, //仓库ID
            depotName: data.label,//仓库名称
          }
        }
        this.wPopupFun()
      },
      //仓库选择弹框
      wPopupFun(isFlay = false) {
        this.showCity = isFlay
      },
      //入库时间
      getTimeFun(data) {
        this.$set(this.parmsJson, 'warehousingTime', data + ':00:00')
        this.$set(this, 'warehousingTime', data + '时')
        this.datePopupFun()
      },
      //时间弹框
      datePopupFun(isFlay = false) {
        this.showDate = isFlay
      },
      //点击收货、收费标准
      jumpStandards(e) {
        e.preventDefault()
        localStorage.setItem('parmsJson', JSON.stringify(this.parmsJson))
        this.$router.push({ path: '/m_standards' });
      },
      //点击选择货品明细
      addGoodsFun(e) {
        e.preventDefault()
        let self = this
        let parmsJson = JSON.parse(JSON.stringify(this.parmsJson))
        if (!parmsJson.corpId) {//客户id不存在，登录失效或者不是客户
          if (!parmsJson.companyName) {
            wx.showModal({
              content: '请填写客户名称！',
              showCancel: false,
            })
            return
          }
          if (!parmsJson.mobile) {
            //getApp().showMsg()
            wx.showModal({
              content: "客户手机号失效，请重新登录，或者联系管理员！",
              showCancel: false,
            })
            return
          }

          //客户不存在时，新建客户
          let params = {
            companyName: this.parmsJson.companyName,
            mobile: this.parmsJson.mobile
          }
          self.$axios('post', API.createCustomer, params).then((res) => {

            let data = getRes.data
            let userId = data.userId ? data.userId : ''
            let newUserJson = { ...userJson, corpId: data.corpId }

            //更新客户信息
            localStorage.setItem('user', newUserJson)
            self.userMsgFun(newUserJson)

          }).catch((error) => {
            console.log(error);
          })
        }
        localStorage.setItem('parmsJson', JSON.stringify(this.parmsJson))
        this.$router.push({ name: 'm_goods_list', params: { type: 'in', id: userJson.corpId || '' } });
      },
      //选择入库需求
      warehousingTypeFun(e) {
        this.$set(this.parmsJson, 'warehousingType', e.value)
      },
      //填写商品数量
      inputEnterNumberFun(item, index) {
        let parmsJson = this.parmsJson
        let data = totalFun(this.parmsJson.commodityJson, 'enterNumber')//统计箱、kg、方
        this.parmsJson = {
          ...parmsJson,
          ...data
        }
      },
      //删除商品
      deleteFun(index) {
        let self = this
        let parmsJson = this.parmsJson
        self.$dialog.confirm({
          title: '确定要删除吗？',
        }).then(() => {
          parmsJson.commodityJson.splice(index, 1)
          self.inputEnterNumberFun()
        }).catch(() => { })
      },
      jumpFun() {
        localStorage.removeItem('parmsJson') //清空缓存
        this.$router.push({ path: '/m_out' });
      },
    },
  };
</script>
<style scoped>
  @import url('../../../assets/css/mobile/warehouse.css')
</style>